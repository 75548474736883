/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Subtitle, Fullmap, FullmapWrap, FullmapCover, Image, Text, Title, ContactForm } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kontakt"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pb--60 pt--60" name={"uvod"}>
          
          <ColumnWrap className="column__flex --center el--1 pb--0 pl--0 pr--0 pt--0 flex--center" style={{"maxWidth":1280}} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 pt--0" anim={"5"} animS={"5"} style={{"maxWidth":900}}>
              
              <Subtitle className="subtitle-box" content={"Autoservis Bláža Ostrava - Radvanice&nbsp;<br><br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap name={"3iw2mwnpt8t"}>
          
          <FullmapWrap className="pb--20 pt--20">
            
            <Subtitle className="subtitle-box subtitle-box--center" content={"&nbsp;Bláža steel s.r.o.&nbsp;Lihovarská 1378/11 Ostrava - Radvanice&nbsp; &nbsp;tel : +420<a href=\"+420777321587\"> 777 321 587</a>&nbsp;"}>
            </Subtitle>

          </FullmapWrap>

          <FullmapWrap className="--full">
            
            <FullmapCover place={"Lihovarská 1378/11 Ostrava - Radvanice"}>
            </FullmapCover>

          </FullmapWrap>

        </Fullmap>


        <Column name={"kontakt-1"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="pb--0 pt--0" style={{"maxWidth":1050}}>
              
              <Image className="pb--0 pt--0" src={"https://cdn.swbpg.com/t/i/template/156/img-2_s=350x_.jpg"} svg={false} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/i/template/156/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/156/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/156/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/156/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/156/img-2_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"kontakt-2"}>
          
          <ColumnWrap className="column__flex --center el--1 pb--0 pl--0 pr--0 pt--0 flex--center" style={{"maxWidth":1280}} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 pb--0 pt--0" anim={"5"} animS={"5"} style={{"maxWidth":900}}>
              
              <Text className="text-box fs--18" content={"Bláža steel s.r.o<br>Lihovarska 1378/11<br>Ostrava - radvanice<br>Areal baňských strojíren&nbsp;<br>Tel: <a href=\"+420777321587\">+420 777 321 587&nbsp;</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1rkryxn css-42e4bw pb--60 pt--60" name={"kontakt-3"} parallax={false} css={css`
      
    background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen-2_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen-2_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen-2_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen-2_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen-2_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen-2_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen-2_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen-2_s=3000x_.jpg);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s2" anim={"7"} animS={"2"}>
              
              <Title className="title-box fs--72 mt--16" style={{"maxWidth":966}} content={"<span style=\"color: white;\">Máte dotaz?</span><br>"}>
              </Title>

              <ContactForm className="--shape2 --style1 fs--18 mt--40" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Odeslat","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"paticka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 el--3" anim={"2"} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-custom-2);\">Autoservis Bláža Ostrava - Radvanice</span><br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"&nbsp;Pro Vaše auto uděláme vše, co budeme moct.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">O nás</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"Jsme Malý a nezávislý autoservis  osobních i užitkových vozidel. \nMěření a seřízení geometrie kol \nServis čištění a plnění klimatizací \nRychloservis\nPneuservis\nNástřiky podvozku a dutin \nStrojní regenerace filtru pevných částic DPF\na jiné.&nbsp;<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Kontakt</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":473}} content={"Bláža steel s.r.o\nLihovarska 1378/11\nOstrava - radvanice\nAreal baňských strojíren<br><a href=\"+420777321587\">tel: +420 777 321 587</a>&nbsp;&nbsp;"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: white;\">IČ:: 63471710<br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}